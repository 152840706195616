import React from 'react';
import "./SaltybridgeSection18.scss"
import "../../../scss/components/saltybridge/heading.scss"

import {Img} from 'react-image'
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next'

import img_1 from '../../../images/ortho/MD_06506.jpg';
import img_2 from '../../../images/ortho/lobi1.jpg';
import img_3 from '../../../images/ortho/20230112_154940.jpg';

export default function SaltybridegSection18(){
  const {t} = useTranslation()

  const ArticleComponent = function (props){
    return <div className={"article "+props.className}>
    <div className="zaglavlje">
      <a className="article-link">
        <Img className="article-img" src={props.image}></Img>
      </a>
      <h2>{props.clientName}</h2>
      <div className="article-info">
        <span><i className="icon-calendar"/> <em>{props.date}</em></span>
      </div>
      <div className="line-span"></div>
    </div>
    <p style={{lineHeight: "1.75",marginBottom: "2rem"}}>{props.text}</p>
  </div>

  }

    return (
<div id="sbcom-orth7">
  <div className="container">
    <div className="content-padding">
        <div className="row">
          <div className="col-sm-12">
            <div className="sbcom-post">
              <ArticleComponent className="c1" image={img_1} date={t('sbcom_ss18_patient_orth_date1')} clientName={t('sbcom_ss18_patient_orth_name1')} text={t('sbcom_ss18_patient_orth_text1')}/>
              <ArticleComponent className="c2" image={img_2} date={t('sbcom_ss18_patient_orth_date2')} clientName={t('sbcom_ss18_patient_orth_name2')} text={t('sbcom_ss18_patient_orth_text2')}/>
              <ArticleComponent className="c3" image={img_3} date={t('sbcom_ss18_patient_orth_date3')} clientName={t('sbcom_ss18_patient_orth_name3')} text={t('sbcom_ss18_patient_orth_text3')}/>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>)
}