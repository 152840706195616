import React from 'react';
import './HeaderPage.scss';

const HeaderPage = (props) =>{ 
  const name = props.name
  {/*const image = props.image*/}
  
  return (
  <header id="sbcom-header" className="sbcom-cover sbcom-cover-sm" role="banner" 
  style={{/*backgroundColor: "rgba(17,17,17,0.1)"*/background: "linear-gradient(133deg, rgba(16,165,220,1) 0%, rgba(5,67,123,1) 100%)",height: "300px"}/*{{backgroundImage:`url(${image})`}}*/}
  data-stellar-background-ratio="0.5">
    {/*<div className="overlay"></div>*/}
    <div className="container">
      <div className="row">
        <div className="col-md-7 text-left">
          <div className="display-t" style={{height: "300px"}}>
            <div className="display-tc animate-box" style={{height: "300px"}} data-animate-effect="fadeInUp">
              {/*<h1 className="mb30">{t('sbcom_header_page_text1')}</h1>*/}
              <h1 className="mb31">{name}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
)};

HeaderPage.propTypes = {};

HeaderPage.defaultProps = {};

export default HeaderPage;
